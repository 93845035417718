var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "CCard",
        [
          _c(
            "CCardHeader",
            {
              staticClass: "admin-card-header dashboard-card-header",
              attrs: { color: _vm.theme }
            },
            [
              _c("CIcon", { attrs: { name: "cil-map" } }),
              _vm._v(" Vehicle Tracking ")
            ],
            1
          ),
          _c(
            "CCardBody",
            { staticClass: "m-0 p-0" },
            [
              _c(
                "CRow",
                { staticClass: "no-gutters" },
                [
                  _c(
                    "CCol",
                    {
                      staticClass: "p-0",
                      attrs: { lg: "8", md: "6", sm: "6" }
                    },
                    [
                      _c(
                        "CCard",
                        {
                          staticClass: "admin-card",
                          attrs: { color: "light" }
                        },
                        [
                          _c(
                            "CCardBody",
                            { staticClass: "overflow-none p-0 map-container" },
                            [
                              _vm._t("map", [
                                _c("VueFriendlyIFrame", {
                                  staticClass: "embedded-content-lg",
                                  attrs: {
                                    id: "map-frame",
                                    src: _vm.mapSrc,
                                    width: "100%",
                                    height: "100%"
                                  }
                                })
                              ])
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "CCol",
                    { staticClass: "p-0 ", attrs: { lg: "", sm: "" } },
                    [
                      _c(
                        "CCard",
                        { staticClass: "admin-card h-100 pl-1 " },
                        [
                          _c(
                            "CCardBody",
                            { staticClass: " p-0 overflow-auto" },
                            [
                              _c("StatusGrid", {
                                attrs: { items: _vm.sortedStatus },
                                on: { "row-clicked": _vm.handleRowClick }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }