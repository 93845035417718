var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("CDataTable", {
    attrs: {
      "table-filter": true,
      hover: _vm.hover,
      striped: true,
      border: _vm.border,
      small: _vm.small,
      fixed: _vm.fixed,
      items: _vm.items,
      fields: _vm.fields,
      "items-per-page": _vm.small ? 10 : 4,
      dark: _vm.dark,
      "clickable-rows": true,
      pagination: ""
    },
    on: { "row-clicked": _vm.handleRowClick },
    scopedSlots: _vm._u([
      {
        key: "status",
        fn: function(ref) {
          var item = ref.item
          return [
            _c(
              "td",
              [
                _c(
                  "CBadge",
                  { attrs: { color: _vm.getBadgeFromState(item.state) } },
                  [
                    _c("CIcon", { attrs: { name: "cil-truck" } }),
                    _vm._v(" " + _vm._s(item.status))
                  ],
                  1
                )
              ],
              1
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }