<template>
  <div>
    <CCard>
      <CCardHeader class="admin-card-header dashboard-card-header" :color="theme"
        ><CIcon name="cil-map" /> Vehicle Tracking
      </CCardHeader>
      <CCardBody  class="m-0 p-0">
        <CRow class="no-gutters">
          <CCol lg="8" md="6" sm="6" class="p-0">
            <CCard class="admin-card" color="light">
              <CCardBody class="overflow-none p-0 map-container">
                <slot name="map">
                  <VueFriendlyIFrame 
                  id="map-frame"
                  class="embedded-content-lg"
                  :src="mapSrc"
                  width="100%"
                  height="100%"
                  
                >
                </VueFriendlyIFrame>
                </slot>
                
              </CCardBody>
            </CCard>
          </CCol>
          <CCol lg="" sm="" class="p-0 ">
            <CCard class="admin-card h-100 pl-1 ">
              <CCardBody class=" p-0 overflow-auto">
                <StatusGrid :items="sortedStatus"
                @row-clicked="handleRowClick"
                 />
                
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
  </div>
</template>


<style scoped>
.admin-card {
  /*background-color: var(--light);*/
  height: 100%;
}

.admin-card-header {
  /*background-color: var(--light);*/
  padding: 5px;
  color: var(--white)
}
.embedded-content-lg {
  width: 100%;
  height: 100%;
  border-style: none;
  overflow: hidden;
  overflow-y: hidden;
  overflow-x: hidden;
}

#map-frame {
  min-height: 400px;
}
.map-container {
  min-height: 400px;
  height: 100%;
}
</style>


<script>
import { cibElsevier } from "@coreui/icons";

import StatusGrid from "../components/tables/StatusGrid";
import VueFriendlyIFrame from "../components/base/VueFriendlyIFrame"; 

const defaultMapSrc = ()=>{
  return $api.config.worldmap;
  "https://poc.aarcomm.io/dev/lonestar-fleet-map"; 
}

export default {
  name: "LiveTrackingWidget",
  components: {
    StatusGrid,
    VueFriendlyIFrame
  },
  props: {
    status: Array,
    
    theme: {
      type: String, 
      default: "gradient-info",
    }
  
    
  },
  data() {
    return {
      mapSrc: "",
    };
  },
  computed: {
    sortedStatus() {
      return this.reorderArray(this.status);
    },
    defaultMapSrc (){
      return this.$api.config.worldmap;
    },
    
  },
  created(){
    
    this.mapSrc = this.defaultMapSrc //this.$api.config.worldmap; // 
    
    if(!this.mapSrc) // Fallback to default. 
      this.mapSrc = this.defaultMapSrc; 
      console.log(this.mapSrc);
  },
  mounted(){
    
  },
  methods: {
      
      handleRowClick(item,index){
        // Just Propogate the event updwards for now 
      this.$emit("row-clicked", item, index); 
    }, 
    /** Re-Orders the Array based on Recent Activity / Device */
    reorderArray(arr) {
      // return arr;
      // TODO:
      let tempArray = arr;

      tempArray.sort((a, b) => {
        if (a.state > b.state) return -1;
        else return 0;
      });
      return tempArray;
    },
  },
};
</script>