
<template>
      <CDataTable
        :table-filter="true"
        :hover="hover"
        :striped="true"
        :border="border"
        :small="small"
        :fixed="fixed"
        :items="items"
        :fields="fields"
        :items-per-page="small ? 10 : 4"
        :dark="dark"
        :clickable-rows="true"
        pagination
        @row-clicked="handleRowClick"
      >
        <template #status="{item}">
          <td>
            <CBadge :color="getBadgeFromState(item.state)"><CIcon name="cil-truck" /> {{item.status}}</CBadge>
          </td>
        </template>
      </CDataTable>
</template>


<script>


export default {
    name: "StatusGrid",
    components: {
        
    },
    props: {
    items: Array,
    fields: {
      type: Array,
      default () {
        return ['name', {key: 'status', label: 'Status'}, 'branch']
      }
    },
    caption: {
      type: String,
      default: 'Table'
    },
    hover: Boolean,
    striped: {type: Boolean,
      default: true,
    },
    border: Boolean,
    small: Boolean,
    fixed: Boolean,
    dark: Boolean
  },
  methods: {

    handleRowClick(item,index){
      console.log(item,index); 
      this.$emit("row-clicked", item, index); 
    }, 
    // TODO: this should actually use the state enumeration instead of strings
    getBadge (status) {
      switch(status){
        case 'active':return 'success'
        case 'moving': return 'success'
        case 'inactive': return 'secondary'
        case 'idle': return 'warning'
        default: return 'danger'
      }
    },

    getBadgeFromState (state) {
      switch(state){
        case 2 :return 'success' // Active 
        case 1: return 'warning' // Device is Idle 
        case 0: return 'danger'  // Device is Stopped 
        default: return 'primary' // Anything Else, 
      }
    }
      
  }
}
</script>